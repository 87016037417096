<template>
  <div class="category_page">
    <div class="top_holder"></div>
    <!-- 面包屑 -->
    <div class="breadcrumb web-center" style="margin: 40px auto 20px">
      <a-breadcrumb>
        <a-breadcrumb-item
          ><a href="#/extension">插件中心</a></a-breadcrumb-item
        >
        <a-breadcrumb-item
          ><a href="javascript:;">插件分类</a></a-breadcrumb-item
        >
      </a-breadcrumb>
    </div>
    <!-- 分类导航 -->
    <div class="category_nav web-center">
      <div class="category_nav_content">
        <div class="nav_left">一级分类:</div>
        <div class="nav_center">
          <span
            class="nav_center_item"
            :class="{ active: !categoryFirstId }"
            @click="changeSecondCategory('')"
            >全部</span
          >
          <span
            class="nav_center_item"
            v-for="item in showCategoryData"
            :key="item.id"
            :class="{ active: categoryFirstId == item.id }"
            @click="changeSecondCategory(item.id)"
            >{{ item.title }}</span
          >
        </div>
        <div class="nav_right" @click="getMoreCategory('first')">
          {{ isShowAllCategoryData ? "收起" : "更多"
          }}<a-icon :type="isShowAllCategoryData ? 'up' : 'down'" />
        </div>
      </div>
      <div class="category_nav_content">
        <div class="nav_left">二级分类:</div>
        <div class="nav_center">
          <span
            class="nav_center_item"
            :class="{ active: !categorySecondId }"
            @click="categorySecondId = ''"
            >全部</span
          >
          <span
            class="nav_center_item"
            v-for="item in showCategorySecondData"
            :key="item.id"
            :class="{ active: categorySecondId == item.id }"
            @click="categorySecondId = item.id"
            >{{ item.title }}</span
          >
        </div>
        <div class="nav_right" @click="getMoreCategory('second')">
          {{ isShowAllCategorySecondData ? "收起" : "更多"
          }}<a-icon :type="isShowAllCategorySecondData ? 'up' : 'down'" />
        </div>
      </div>
    </div>
    <!-- 排序和筛选 -->
    <div class="filter_content">
      <a-select default-value="create_time" style="width: 100px" v-model="sort">
        <a-select-option value="create_time"> 最新发布 </a-select-option>
        <a-select-option value="install_total"> 最热添加 </a-select-option>
      </a-select>
      <a-checkbox @change="onChange"> 只显示已添加 </a-checkbox>
    </div>
    <!-- 列表展示 -->
    <div class="list_content web-center">
      <List :listData="listData" :column="4"></List>
      <!-- 空白页 -->
      <div class="noResult" v-show="!listData.length">
        <img
          src="@/assets/img/extension/search_noResult.png"
          alt=""
          class="img"
        />
        <p class="noResult_title">暂无内容</p>
      </div>
    </div>

    <!-- 分页器 -->
    <div v-show="listData.length" class="pagination web-center">
      <div class="flex_holder"></div>
      <a-pagination
        v-model="currentPage"
        :total="total"
        :pageSize="pageSize * 1"
        @change="changePage"
      />
    </div>
  </div>
</template>

<script>
import { pluginCategory, pluginList, pluginInfo } from "@/api/extension.js";
import List from "../components/List.vue";
export default {
  components: {
    List,
  },
  data() {
    return {
      categoryFirstId: "",
      categorySecondId: "",
      currentPage: 1,
      total: 50,
      categoryData: [],
      showCategoryData: [],
      isShowAllCategoryData: false,
      categorySecondData: [],
      showCategorySecondData: [],
      isShowAllCategorySecondData: false,
      sort: "create_time",
      onlyShowInstall: 0, //1为只展示已安装插件，0为展示所有
      pageSize: "16",
      listData: [],
    };
  },
  created() {
    pluginCategory().then((res) => {
      const data = res.data.data;
      this.categoryData = Object.values(data);
      this.showCategoryData = this.categoryData.slice(0, 8);
      this.categorySecondData = this.categoryData.reduce(
        (prev, next) => prev.concat(next.children),
        []
      );
      this.showCategorySecondData = this.categorySecondData.slice(0, 8);
    });
    if (this.$route.query.id) {
      this.categoryFirstId = this.$route.query.id;
    }
    this.getListData();
  },
  methods: {
    changeSecondCategory(id) {
      this.categoryFirstId = id;
      this.categorySecondId = "";
      this.isShowAllCategorySecondData = false;
      if (id != "") {
        this.categorySecondData = this.categoryData.find(
          (item) => item.id == id
        ).children;
      } else {
        this.categorySecondData = this.categoryData.reduce(
          (prev, next) => prev.concat(next.children),
          []
        );
      }
      this.showCategorySecondData = this.categorySecondData.slice(0, 8);
    },
    getMoreCategory(type) {
      if (type == "first") {
        this.isShowAllCategoryData = !this.isShowAllCategoryData;
        this.showCategoryData = this.isShowAllCategoryData
          ? this.categoryData
          : this.categoryData.slice(0, 8);
      } else {
        this.isShowAllCategorySecondData = !this.isShowAllCategorySecondData;
        this.showCategorySecondData = this.isShowAllCategorySecondData
          ? this.categorySecondData
          : this.categorySecondData.slice(0, 8);
      }
    },
    getListData() {
      pluginList({
        ...this.paramsObj,
      }).then(({ data }) => {
        this.listData = data.data.list;
        this.total = data.data.total;
      });
    },
    // handleChange(value) {
    // },
    onChange(e) {
      this.onlyShowInstall = e.target.checked ? 1 : 0;
    },
    changePage(page, pageSize) {
    },
  },
  computed: {
    //此为请求插件列表的参数，如果发生变化立即重新请求数据
    paramsObj() {
      return {
        category_first_id: this.categoryFirstId, //一级分类id
        category_second_id: this.categorySecondId, //二级分类id
        pagesize: this.pageSize, //每页数据
        page: this.currentPage + "", //当前页码
        sort: this.sort, //排序 最新发布 create_time 最热 install_total  默认create_time
        show_install: this.onlyShowInstall, //是否展示已添加插件 1则只展示已安装插件，0为展示所有 默认0
      };
    },
  },
  watch: {
    //监听paramsObj，变化后重新请求数据
    paramsObj: {
      handler(newVal) {
        this.getListData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.category_page {
  .top_holder {
    height: 60px;
    background-color: #fff;
  }
  .category_nav {
    padding: 16px 20px;
    background: #fff;
    border-radius: 2px;
    font-size: 14px;
    color: #374567;
    .category_nav_content {
      .flex(@align:flex-start);
      .nav_left {
        width: 70px;
        line-height: 28px;
      }
      .nav_center {
        width: 964px;
        flex: none;
        .flex(@justify:flex-start,@wrap:wrap);
        .nav_center_item {
          line-height: 28px;
          padding: 0 12px;
          margin-right: 20px;
          margin-bottom: 16px;
          cursor: pointer;
          &.active {
            background: #ecf2ff;
            color: #4c84ff;
            border-radius: 2px;
            flex: none;
          }
        }
      }
      .nav_right {
        padding: 0 12px;
        color: #878fa7;
        line-height: 28px;
        background: #f4f4f4;
        border-radius: 2px;
        cursor: pointer;
        &:hover {
          background: #ecf2ff;
          color: #4c84ff;
        }
      }
    }
  }
  .filter_content {
    margin: 28px auto 20px;
    width: 1200px;
    .ant-select {
      margin-right: 40px;
    }
  }
  .list_content {
    height: 915px;
    .noResult {
      width: 100%;
      height: 642px;
      .flex(center;center;@direction: column);
      .img {
        width: 280px;
        height: 142px;
      }
      .noResult_title {
        margin: 12px auto;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #374567;
        line-height: 25px;
      }
    }
  }

  .pagination {
    margin-top: 50px;
    .flex();
    // /deep/ .ant-pagination-item-active {
    //   font-weight: 500;
    //   background: #374567;
    //   color: #fff;
    // }
  }
}
</style>